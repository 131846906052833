import React, { useState } from "react";
import SelectedAccessories from "../SelectedAccessories.jsx";
import Wardrobe from "../../pages/Wardrobe";
import SelectedFootWear from "../SelectedFootWear.jsx";
import ScrapingLinkData from "../../pages/ScrapingLinkData.jsx";

import Footwear from "../../pages/Footwear";
const Component3 = () => <div>This is Component 3</div>;
const Component4 = () => <div>This is Component 4</div>;

const tabData = [
  { key: "tab2", label: "Accessories", component: "Component2" },
  { key: "tab3", label: "Foot Wear", component: "Component3" },
  { key: "tab4", label: "Scraping", component: "Component4" },
];
const tabDataWardrobe = [
  { key: "tab1", label: "Wardrobe", component: "Component1" },
  { key: "tab2", label: "Accessories", component: "Component2" },
  { key: "tab3", label: "Foot Wear", component: "Component3" },
];
const CollageImageData = ({ SelectUserData }) => {
  console.log(SelectUserData, "tabDataWardrobe.......................");
  const [activeTab, setActiveTab] = useState(tabData[0].key);

  const renderTabContent = () => {
    switch (activeTab) {
      case "tab1":
        return <Wardrobe SelectUserData={SelectUserData} />;
      case "tab2":
        return <SelectedAccessories SelectUserData={SelectUserData} />;
      case "tab3":
        return <SelectedFootWear SelectUserData={SelectUserData} />;
      case "tab4":
        return <ScrapingLinkData SelectUserData={SelectUserData} />;
      default:
        return <div>Select a tab to see content</div>;
    }
  };

  return (
    <div style={{width:'100%'}}>
      {/* Render the tabs dynamically */}

      <div className="user-wardrobe scroll">
        {(SelectUserData.service_category === "Online Shopping"
          ? tabData
          : tabDataWardrobe
        ).map((tab) => (
          <button
            key={tab.key}
            className={`button ${activeTab === tab.key ? "selected" : ""}`}
            onClick={() => setActiveTab(tab.key)}
          >
            {tab.label}
          </button>
        ))}
      </div>

      {/* Render the content of the selected tab */}
      <div className="tab-content"  >{renderTabContent()}</div>
    </div>
  );
};

export default CollageImageData;
