// import React, { useState } from "react";
// import {
//   MenuFoldOutlined,
//   MenuUnfoldOutlined,
//   UserOutlined,
//   HomeOutlined,
//   HistoryOutlined,
// } from "@ant-design/icons";
// import { Button, Layout, Menu, theme } from "antd";
// import { Link } from "react-router-dom";
// import DashboardRoutes from "../routes/LayoutRoutes";
// import "./css/DashboardLayout.css";
// const { Header, Sider, Content } = Layout;

// const DashboardLayout = () => {
//   const [collapsed, setCollapsed] = useState(false);
//   const {
//     token: { colorBgContainer, borderRadiusLG },
//   } = theme.useToken();

//   // Configuration array for menu items
//   const menuItems = [
//     {
//       key: "1",
//       icon: <HomeOutlined />,
//       path: "/dashboard",
//       label: "Home",
//     },
//     {
//       key: "2",
//       icon: <HistoryOutlined />,
//       path: "/dashboard/History",
//       label: "History",
//     },
//     {
//       key: "3",
//       icon: <UserOutlined />,
//       path: "/dashboard/Profile",
//       label: "Profile",
//     },
//   ];

//   return (
//     <Layout style={{ height: "100vh" }}>
//       <Sider
//         trigger={null}
//         collapsible
//         collapsed={collapsed}
//         style={{
//           background: "#fff", // Set the background to white
//         }}
//       >
//         <div className="demo-logo-vertical" />
//         <Menu
//           theme="light" // Change theme to light to match the white background
//           mode="inline"
//           defaultSelectedKeys={["1"]}
//           style={{
//             color: "#000", // Set the tab text color to black
//           }}
//           items={menuItems.map((item) => ({
//             key: item.key,
//             icon: React.cloneElement(item.icon, { style: { color: "#000" } }), // Ensure icon color is black
//             label: <Link to={item.path}>{item.label}</Link>,
//           }))}
//         />
//       </Sider>
//       <Layout>
//         {/* <Header
//           style={{
//             padding: 0,
//             background: colorBgContainer,
//           }}
//         >
//           <Button
//             type="text"
//             icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
//             onClick={() => setCollapsed(!collapsed)}
//             style={{
//               fontSize: '16px',
//               width: 64,
//               height: 64,
//             }}
//           />
//           <div>
//           <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSLU5_eUUGBfxfxRd4IquPiEwLbt4E_6RYMw&s'alt='' width={'20px'}/>
//           <p>Name</p>

//           </div>

//         </Header> */}

//         <Header
//           className="header"
//           style={{
//             padding: "0 16px", // Add padding to the left and right
//             background: colorBgContainer,
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "space-between", // Distribute space between the elements
//           }}
//         >
//           <div
//             style={{
//               display: "flex",
//               alignItems: "center",
//               width: "100vw",
//               justifyContent: "space-between",
//               paddingRight: "3%",
//             }}
//           >
//             <Button
//               type="text"
//               icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
//               onClick={() => setCollapsed(!collapsed)}
//               style={{
//                 fontSize: "16px",
//                 width: 64,
//                 height: 64,
//               }}
//             />
//             <div
//               style={{ display: "flex", alignItems: "center", marginLeft: 16 }}
//             >
//               <img
//                 src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSLU5_eUUGBfxfxRd4IquPiEwLbt4E_6RYMw&s"
//                 alt=""
//                 width={"40px"}
//                 style={{ marginRight: 8 }}
//               />
//               <p style={{ margin: 0 }}>Name</p>
//             </div>
//           </div>
//         </Header>

//         <Content
//           style={{
//             margin: "24px 16px",
//             // padding: 24,
//             minHeight: 280,

//             borderRadius: borderRadiusLG,
//           }}
//         >
//           <DashboardRoutes /> {/* Nested Routes inside Dashboard */}
//         </Content>
//       </Layout>
//     </Layout>
//   );
// };

// export default DashboardLayout;

import React, { useState } from "react";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  HomeOutlined,
  HistoryOutlined,
  NotificationOutlined,
  CustomerServiceOutlined,
  SecurityScanOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { Button, Layout, Menu, theme } from "antd";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import LayoutRoutes from "../routes/LayoutRoutes";
import "./css/DashboardLayout.css";
import config from "../config";
import Profile from "../images/profile.jpg";
import logo from "../images/logo.svg";
const { Header, Sider, Content } = Layout;

const DashboardLayout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();

  const LoginUser = JSON.parse(sessionStorage.getItem("LoginUser"));
  const shouldIncludeManageUser = LoginUser?.ROLE === "0";
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  function capitalizeFirstLetter(str) {
    console.log(str, "str data ,.............");
    if (!str) return str; // Check if the string is empty
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
  const menuItems = [
    {
      key: "1",
      icon: <HomeOutlined />,
      path: "/dashboard",
      label: "Home",
    },
    // {
    //   key: "2",
    //   icon: <HistoryOutlined />,
    //   path: "/dashboard/History",
    //   label: "History",
    // },
    {
      key: "3",
      icon: <NotificationOutlined />,
      path: "/dashboard/Notifications",
      label: "Notifications",
    },
    {
      key: "4",
      icon: <CustomerServiceOutlined />,
      path: "/dashboard/ServiceHistory",
      label: "History",
    },
    ...(shouldIncludeManageUser
      ? [
          {
            key: "5",
            icon: <UserOutlined />,
            path: "/dashboard/Manageuser",
            label: "Manage User",
          },
        ]
      : []),
    // {
    //   key: "5",
    //   icon: <UserOutlined />,
    //   path: "/dashboard/Manageuser",
    //   label: "Manage User",
    // },
    // {
    //   key: "6",
    //   icon: <UserOutlined />,
    //   path: "/dashboard/AboutUs",
    //   label: "About Us",
    // },
    // {
    //   key: "7",
    //   icon: <CustomerServiceOutlined />,
    //   path: "/dashboard/CustomerService",
    //   label: "Customer Service",
    // },
    {
      key: "8",
      icon: <SecurityScanOutlined />,
      path: "/dashboard/ChangePassword",
      label: "Change Password",
    },
    {
      key: "9",
      icon: <UserOutlined />,
      path: "/dashboard/Profile",
      label: "Profile",
    },
  ];

  const handleLogout = async () => {
    // Add any necessary logout logic here (e.g., clearing authentication tokens)
    await sessionStorage.clear();
    navigate("/login"); // Redirect to the login page
  };

  const logoutItem = {
    key: "9",
    icon: <LogoutOutlined />,
    path: "/dashboard/Logout",
    label: "Logout",
    onClick: handleLogout,
  };
  function extractBaseUrl(url) {
    // Split the URL by "https://" and filter out any empty parts
    const parts = url.split("https://").filter(Boolean);

    // Construct the base URL by joining the last part with "https://"
    return `https://${parts[parts.length - 1]}`;
  }
  return (
    <Layout style={{ height: "100vh" }}>
      <Sider
        className="DashboardLayout-Sider"
        id={collapsed ? "sidenavbarsmall" : "sidenavbar"}
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{
          background: "#FBF1E9", // Set the background to white
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "10%",
            }}
          >
            <img src={logo} width={"50%"} />
          </div>
          {/* <div className="demo-logo-vertical" /> */}
          <Menu
            theme="light" // Change theme to light to match the white background
            mode="inline"
            className="DashboardLayout-Side-Menu"
            defaultSelectedKeys={["1"]}
            style={{
              color: "#000", // Set the tab text color to black
              fontSize: "18px",
            }}
            items={menuItems.map((item) => ({
              key: item.key,
              icon: React.cloneElement(item.icon, { style: { color: "#000" } }), // Ensure icon color is black
              label: <Link to={item.path}>{item.label}</Link>,
            }))}
          />
        </div>
        <Menu theme="light" mode="inline" className="DashboardLayout-Side-Menu">
          <div
            style={{
              display: "flex",
              margin: "0px 29px",
              fontSize: "9px",
            }}
          >
            <p> Version :- 1.0.2</p>
          </div>
          <Menu.Item
            key={logoutItem.key}
            icon={logoutItem.icon}
            onClick={logoutItem.onClick} // Attach the logout handler
          >
            {logoutItem.label}
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Header
          className="header"
          style={{
            padding: "0 16px", // Add padding to the left and right
            background: "#FBF1E9",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between", // Distribute space between the elements
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100vw",
              justifyContent: "space-between",
              paddingRight: "3%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
                style={{
                  fontSize: "16px",
                  width: 64,
                  height: 64,
                }}
              />
              <p style={{ fontSize: "18px", fontWeight: "600" }}>
                {LoginUser?.ROLE === "0"
                  ? "Welcome to Admin Dashboard"
                  : "Welcome to Stylist Dashboard"}
              </p>

              <p></p>
            </div>
            <div
              style={{ display: "flex", alignItems: "center", marginLeft: 16 }}
            >
              <img
                src={`${
                  LoginUser?.USER_PHOTO
                    ? extractBaseUrl(
                        `${config.imageUrl}${LoginUser?.USER_PHOTO}`
                      )
                    : Profile
                }`}
                alt=""
                width={"50px"}
                height={"50px"}
                style={{ marginRight: 8, borderRadius: "50%" }}
              />
              {/* <div style={{}}> */}
              <p style={{ fontSize: " 20px", margin: 0 }}>
                {capitalizeFirstLetter(
                  LoginUser?.FIRST_NAME ? LoginUser?.FIRST_NAME : "No name "
                )}
              </p>
              <p
                style={{
                  marginLeft: "5px",
                  fontSize: " 13px",
                  color: "#000000b0",
                  marginBottom: " 0px",
                }}
              >
                {LoginUser?.ROLE === "0" ? "Admin" : "Stylist"}
              </p>
              {/* </div> */}
            </div>
          </div>
        </Header>

        <Content
          className="mainContainerDiv"
          style={{
            margin: "24px 16px",
            // padding: 24,
            minHeight: 280,
            // background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <LayoutRoutes />
        </Content>
      </Layout>
    </Layout>
  );
};

export default DashboardLayout;
